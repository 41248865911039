import React from "react";

const ContactForm = () => {
  return (
    <div className="container mx-auto p-10">
      <div className="mx-auto w-11/12 sm:w-4/5">
        <h2 className="text-4xl text-center mb-10">Contact Us</h2>
        <p className=" mb-10">
          If you have a question or a comment about one of our professional
          grilling products, please submit the form to the right and a Summerset
          Grills representative will get back to you shortly. You can also
          contact customer service via the phone number below.
        </p>
        <h3 className="text-4xl font-semibold mb-10">Corporate Headquarters</h3>
        <p className="font-semibold">Summerset Professional Grills</p>
        <address className="mb-10">
          14520 Delta Ln <br />
          Suite 105 <br />
          Huntington Beach, CA 92647 <br />
          <a href="tel:8009668126">(800) 966-8126</a> <br />
          <p>Mon-Fri 8:30 am – 5:00 pm (PST)</p>
        </address>
      </div>
      <form
        className="row mx-auto md:w-2/3"
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="flex flex-col sm:flex-row justify-between">
          <p className="w-full sm:w-11/12">
            <input
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight mb-5"
              type="text"
              name="name"
              placeholder="Name"
              required
            />
          </p>
          <p className="hidden sm:block w-1/12"></p>
          <p className="w-full sm:w-11/12">
            <input
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight mb-5"
              type="email"
              name="email"
              placeholder="Email"
              required
            />
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-between">
          <p className="w-full sm:w-11/12">
            <input
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight mb-5"
              type="tel"
              name="phone"
              placeholder="Phone"
              required
            />
          </p>
          <p className="hidden sm:block w-1/12"></p>
          <p className="w-full sm:w-11/12">
            <select
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight mb-5"
              name="state"
              required
            >
              <option disabled="disabled" selected value="">
                State
              </option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </p>
        </div>
        <p>
          <textarea
            className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        mb-5
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            name="message"
            placeholder="Message"
            rows={4}
            required
          ></textarea>
        </p>
        <p>
          <button className="form-button pt-3" type="submit">
            Send
          </button>
        </p>
      </form>
    </div>
  );
};

export default ContactForm;
